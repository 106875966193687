// src/components/Home.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import HeroBanner from './HeroBanner';
import AboutUs from './AboutUs';
// import BrandsSection from './BrandsSection'; // Assuming this is the universal component for both sections
// Import the JSON data
// import lightingBrandsData from '../data/lightingBrands.json'; // Update the path as needed
// import audioBrandsData from '../data/audioBrands.json'; // Update the path as needed
import Services2 from './Services2';
import Brands2 from './Brands2';
import ExteriorAudio from './ExteriorAudio';
import Distributors from './Distributors';
// import MyForm from './MyForm';
// import Quote from './Quote';

// Mock-up of a featured products list, replace with actual logic to select featured products
// const featuredProducts = [
//   { id: 1, name: 'Classic Outdoor Wall Light', price: '$49.99', image: '/images/product1.jpg', category: 'Outdoor Wall Lights' },
//   { id: 2, name: 'Garden Pathway Light', price: '$29.99', image: '/images/product2.jpg', category: 'Pathway Lights' },
//   // Add more featured products as needed
// ];

const Home: React.FC = () => {
  return (
    <main>
      {/* Hero Banner Section */}
      <HeroBanner />
      <AboutUs />
      {/* <MyForm /> */}
      <Services2 />
      <ExteriorAudio />

      <section className="py-2 bg-gray-100">
        <div className="container mx-auto px-4 text-center">

          <h2 className="text-3xl font-bold text-gray-800 mb-5 py-2 px-4">Explore Our Products</h2>
          <Link to="/products" className="mt-1 bg-blue-700 hover:bg-black text-white hover:text-custom-yellow shadow-lg hover:shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300">
            View Products
          </Link>
          <p className="text-gray-600 mb-2 mt-2 py-2 px-4">Check out our wide range of landscape lighting products designed to suit any outdoor space.</p>

        </div>
      </section>
      
      <Brands2 />
      <Distributors />

      {/* Lighting Brands Section
      <BrandsSection sectionTitle="Lighting Solutions" data={lightingBrandsData} />

      {/* Audio Brands Section */}
      {/* <BrandsSection sectionTitle="Audio Solutions" data={audioBrandsData} /> */}

      {/* Our Products Section */}
      <section className="py-2 bg-gray-100">
        <div className="container mx-auto px-4 text-center">

          <h2 className="text-3xl font-bold text-gray-800 mb-5 py-2 px-4">Explore Our Products</h2>
          <Link to="/products" className="mt-1 bg-blue-700 hover:bg-black text-white hover:text-custom-yellow shadow-lg hover:shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300">
            View Products
          </Link>
          <p className="text-gray-600 mb-2 mt-2 py-2 px-4">Check out our wide range of landscape lighting products designed to suit any outdoor space.</p>

        </div>
      </section>

      {/* Featured Items Section */}
      {/* <section className="py-12 bg-gray-100">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">Featured Items</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-5 mb-5">
            {featuredProducts.map((product) => (
              <div key={product.id} className="border rounded-lg p-4 shadow-lg">
                <img src={product.image} alt={product.name} className="rounded mb-4 w-full h-auto mt-4" />
                <h3 className="text-xl font-semibold mb-5 mt-5 ">{product.name}</h3>
                <p>{product.price}</p>
                <Link to={`/products?category=${encodeURIComponent(product.category)}`} className="mt-1 mb-5 bg-blue-700 hover:bg-black text-white hover:text-custom-yellow shadow-lg hover:shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300">
                  View Product
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* Quote Section Styled as a Card
       <section className="py-12 bg-gray-100">
        <div className="container mx-auto px-4">
          <div className="max-w-2xl mx-auto bg-white p-8 rounded-xl shadow-md">
            {/* <h2 className="text-2xl font-bold text-center mb-4">Get a Free Quote</h2> */}
      {/* Include the Quote form component */}
      {/* <Quote /> */}
      {/* </div>
        </div>
      </section> */}

      {/* Our Products Section */}
    </main>
  );
};

export default Home;
