import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './components/Navbar';
import Services2 from './components/Services2';
import Products from './components/Products';
import Home from './components/Home';
import ProductDetail from './components/ProductDetail';
import Contact from './components/Contact';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Areas from './components/Areas';
import { EstimateCartProvider } from './context/EstimateCartContext';
import Quote from './components/Quote';
import AboutUsSection from './components/AboutUsSection';



function App() {
  return (
    <Router>
      <EstimateCartProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services2 />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product/:id" element={<ProductDetail />} />
          <Route path="/areas" element={<Areas />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/quote" element={<Quote />} /> {/* This line is added */}
          <Route path="aboutussection" element={<AboutUsSection />} /> {/* This line is added */}
          {/* Add more routes as needed */}
        </Routes>
        <ToastContainer />
        <Footer />
      </EstimateCartProvider>
    </Router>
  );
}

export default App;
