import React, { createContext, useState, useContext } from 'react';

const EstimateCartContext = createContext();

export const useEstimateCart = () => useContext(EstimateCartContext);

export const EstimateCartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  // Define addToCart within EstimateCartProvider to ensure access to cartItems
  const addToCart = (product, quantity) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find((item) => item.id === product.id);
      if (existingItem) {
        return prevItems.map((item) =>
          item.id === product.id ? { ...item, quantity: item.quantity + quantity } : item
        );
      } else {
        return [...prevItems, { ...product, quantity }];
      }
    });
  };

  // Define removeFromCart within EstimateCartProvider
  const removeFromCart = (productId) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== productId));
  };

  // Define updateQuantity within EstimateCartProvider
  const updateQuantity = (productId, quantity) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => (item.id === productId ? { ...item, quantity } : item))
    );
  };

  // Define getItems within EstimateCartProvider to ensure access to cartItems
  const getItems = () => cartItems;

  return (
    <EstimateCartContext.Provider value={{ cartItems, addToCart, removeFromCart, updateQuantity, getItems }}>
      {children}
    </EstimateCartContext.Provider>
  );
};
