// src/components/Services2.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ServiceCard2 from './ServiceCard2';
import FlipCard from './FlipCard';

// Example service data, replace icons with actual paths and add descriptions
const serviceData = [
  { icon: '/icons/floodlight.jpg', title: 'Spotlights & Flood Lights', description: 'Illuminate vast areas or highlight key features with versatile lighting.' },
  { icon: '/icons/walkwaylight.jpg', title: 'Walkway Lights', description: 'Ensure safe and inviting pathways with elegant lighting solutions.' },
  { icon: '/icons/hardscapelight.jpg', title: 'Hardscape & Masonry', description: 'Enhance your outdoor architecture with integrated lighting designs.' },
  { icon: '/icons/well_light.jpg', title: 'In-ground Well Lights', description: 'Achieve seamless illumination with lights flush with the ground.' },
  { icon: '/icons/indicator.webp', title: 'Indicator Lights', description: 'Guide and inform with discreet yet clear lighting markers.' },
  { icon: '/icons/tapelight.jpg', title: 'Outdoor LED Tape', description: 'Flexible and creative lighting to outline and accentuate features.' },
  { icon: '/icons/pendant.webp', title: 'Ceiling and Pendants', description: 'Bring indoor elegance outdoors with stylish overhead lighting.' },
  { icon: '/icons/wall_mount.webp', title: 'Wall Mount', description: 'Secure and decorative lights that brighten walls and surfaces.' },
  { icon: '/icons/color_light.jpg', title: 'Color Changing', description: 'Dynamic lighting that transforms spaces with vibrant colors.' },
];

const Services2: React.FC = () => {
  const navigate = useNavigate(); // Correctly placed inside the component

  return (
    <section className="py-12 bg-white-400">
      <div className="container mx-auto px-4">


        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold bg-white">Our Services</h1>
          <p className="text-gray-600 mt-4">
            Explore our wide range of landscape lighting & AV services designed to bring your outdoor spaces to life.
          </p>

          <div className="container mx-auto px-4 py-4 text-center">
      <button
          className="mt-4 text-lg bg-black hover:bg-black text-custom-yellow hover:text-custom-yellow shadow-lg shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
          onClick={() =>('/quote')}>
          (631) 306-4602
        </button>
        </div>
        
        </div>



        <div className="py-1 bg-gray-50">
          <div className="container mx-auto px-4">
            <div className="text-center mb-12">
              {/* <h1 className="text-4xl text-gray-800 font-bold">-</h1> */}
              <button
                className="mt-1 bg-blue-700 hover:bg-black text-white hover:text-custom-yellow shadow-lg hover:shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
                onClick={() => navigate('/quote')}>
                GET YOUR FREE ESTIMATE
              </button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {/* Flip Cards for additional services */}
              <FlipCard icon="/images/lighting_banner.webp" title="Landscape Lighting" details="Detail about ELECTRICIAN service here." />
              <FlipCard icon="/images/landscape_audio2.webp" title="Exterior Audio" details="Detail about BUILDER / DEVELOPER service here." />
              <FlipCard icon="/images/interior_banner.jpeg" title="Interior Lighting" details="Detail about EXTERIOR DESIGNER service here." />
              <FlipCard icon="/images/interior_audio2.jpeg" title="Interior Audio" details="Detail about HOMEOWNER service here." />
              
              {/* Add more flip cards as needed */}
            </div>
          </div>
        </div>

        <div className="text-center mb-12 mt-12 pt-5">
          <div className="text-4xl font-bold"></div>
          <div className="text-gray-600 mt-4"></div>
          <h2 className="text-4xl bg-white mt-7">Landscape Lighting</h2>
        </div>






        <div className="grid grid-2 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 mb-2">
          {serviceData.map(service => (
            <ServiceCard2 key={service.title} icon={service.icon} title={service.title} description={service.description} />
          ))}
        </div>

        {/* <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-800 mt-6 mb-6">&</h1>

          
          <p className="text-gray-600 mt-6 text-lg">
            Explore our wide range of landscape lighting & AV services designed to bring your outdoor spaces to life.
          </p>
        </div> */}

        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Additional content */}
        </div>
      </div>
    </section>
  );
};

export default Services2;
