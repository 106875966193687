// src/components/Products.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import productsData from '../data/products.json';
import { Product } from '../types'; // Ensure this is correctly imported
import { useEstimateCart } from '../context/EstimateCartContext';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';


const Products: React.FC = () => {
    const [activeTypeFilter, setActiveTypeFilter] = useState<string>('');
    const [filters, setFilters] = useState<{ category: string; color: string; material: string; IPRating: string; }>({
        category: '',
        color: '',
        material: '',
        IPRating: '',
    });
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

    const handleTypeFilterClick = (type: string) => {
        setActiveTypeFilter(prevType => (prevType === type ? '' : type));
    };

    const { addToCart } = useEstimateCart();

    const handleQuantityChange = (product: Product, quantity: number) => {
        setQuantities(prevQuantities => ({ ...prevQuantities, [product.id]: quantity }));
    };

    const handleAddToEstimate = (product: Product) => {
        const quantity = quantities[product.id] || 1;
        addToCart(product, quantity);

        // Notify the user
        toast.success('Added to estimate!');
    };

    const filteredProducts = productsData.filter((product: Product) =>
        (activeTypeFilter === '' || product.type === activeTypeFilter) &&
        (!filters.category || product.category === filters.category) &&
        (!filters.color || product.color === filters.color) &&
        (!filters.material || product.material === filters.material) &&
        (!filters.IPRating || product.IPRating === filters.IPRating)
    );


    return (
        <div className="container mx-auto p-4 flex">
            <div className="w-full">
                <div className="flex justify-center mb-4 flex-wrap">
                    {['All', 'Landscape Audio', 'LED', 'Path Lights', 'Directional', 'Hardscape'].map(type => (
                        <button
                            key={type}
                            onClick={() => handleTypeFilterClick(type === 'All' ? '' : type)}
                            className={`p-2 m-1 ${activeTypeFilter === type ? 'bg-blue-500 text-white' : 'bg-blue-200'} rounded`}
                        >
                            {type}
                        </button>
                    ))}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6 bg-black p-7">
                    {filteredProducts.map(product => (
                        <div key={product.id} className="border rounded-lg p-4 shadow-lg hover:shadow-custom-yellow bg-white">
                            <img
                                src={product.image}
                                alt={product.name}
                                className="rounded mb-4 w-full sm:w-3/4 md:w-full h-auto mx-auto"
                            />
                            <h3 className="text-xl font-semibold mb-2">{product.name}</h3>
                            <p className="text-sm font-semibold mb-2">{product.brand}</p>
                            <p>{product.price}</p>
                            <div className="flex items-center mb-2">
                                <label htmlFor={`quantity_${product.id}`} className="mr-2">Qty:</label>
                                <input
                                    type="number"
                                    id={`quantity_${product.id}`}
                                    name={`quantity_${product.id}`}
                                    value={quantities[product.id] || 1}
                                    onChange={(e) => handleQuantityChange(product, parseInt(e.target.value))}
                                    min={1}
                                    className="w-16 border rounded text-center"
                                />
                            </div>
                            <button
                                onClick={() => handleAddToEstimate(product)}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2"
                            >
                                Add to Estimate
                            </button>
                            <Link to={`/product/${product.id}`} className="text-blue-600 hover:text-blue-800">View Product</Link>
                        </div>
                    ))}
                </div>
            </div>
            <Toaster />
        </div>
    );
};

export default Products;


// // src/components/Products.tsx
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import productsData from '../data/products.json';
// // import FiltersSidebar from './FiltersSidebar';
// import { Product } from '../types'; // Ensure this is correctly imported
// import { useEstimateCart } from '../context/EstimateCartContext';

// // Filtering at the top of the page
// const Products: React.FC = () => {
//     const [activeTypeFilter, setActiveTypeFilter] = useState<string>('');
//     const [filters, setFilters] = useState<{
//         category: string;
//         color: string;
//         material: string;
//         IPRating: string;
//     }>({
//         category: '',
//         color: '',
//         material: '',
//         IPRating: '',
//     });

//     const handleTypeFilterClick = (type: string) => {
//         setActiveTypeFilter(prevType => (prevType === type ? '' : type));
//     };

//     const { addToCart } = useEstimateCart();

//     // const handleSetFilter = (filterType: 'category' | 'color' | 'material' | 'IPRating', value: string) => {
//     //     setFilters(prevFilters => ({
//     //         ...prevFilters,
//     //         [filterType]: value,
//     //     }));
//     // };

//     const filteredProducts = productsData.filter((product: Product) =>
//         (activeTypeFilter === '' || product.type === activeTypeFilter) &&
//         (!filters.category || product.category === filters.category) &&
//         (!filters.color || product.color === filters.color) &&
//         (!filters.material || product.material === filters.material) &&
//         (!filters.IPRating || product.IPRating === filters.IPRating)
//     );

//     return (
//         <div className="container mx-auto p-4 flex">
//             <div className="flex-1 pl-4">
//                 <div className="flex justify-center mb-4">

//                     {/* clickable categories on the products page */}

//                     {['All', 'Landscape Audio', 'LED', 'Path Lights', 'Directional', 'Hardscape'].map(type => (
//                         <button
//                             key={type}
//                             onClick={() => handleTypeFilterClick(type === 'All' ? '' : type)}
//                             className={`p-2 m-1 ${activeTypeFilter === type ? 'bg-blue-500 text-white' : 'bg-blue-200'} rounded`}
//                         >
//                             {type}
//                         </button>
//                     ))}
//                 </div>

//                 <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 bg-black p-7">
//                     {filteredProducts.map(product => (
//                         <div key={product.id} className="border rounded-lg p-4 shadow-lg hover:shadow-custom-yellow bg-white">
//                             {/* Adjust the image size for smaller screens using Tailwind's width utilities */}
//                             <img
//                                 src={product.image}
//                                 alt={product.name}
//                                 // Tailwind classes for responsiveness
//                                 className="rounded mb-4 w-full sm:w-3/4 md:w-full h-auto mx-auto" // Adjust the 'sm:w-3/4' value as needed
//                             />
//                             <h3 className="text-xl font-semibold mb-2">{product.name}</h3>
//                             <p className="text-sm font-semibold mb-2">{product.brand}</p>
//                             <p>{product.price}</p>
//                             <Link to={`/product/${product.id}`} className="text-blue-600 hover:text-blue-800">View Product</Link>
//                         </div>
//                     ))}
//                 </div>

//                 {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//                     {filteredProducts.map(product => (
//                         <div key={product.id} className="border rounded-lg p-4 shadow-lg">
//                             <img src={product.image} alt={product.name} className="rounded mb-4 w-full h-auto" />
//                             <h3 className="text-xl font-semibold mb-2">{product.name}</h3>
//                             <p className="text-sm font-semibold mb-2">{product.brand}</p>
//                             <p>{product.price}</p>
//                             <Link to={`/product/${product.id}`} className="text-blue-600 hover:text-blue-800">View Product</Link>
//                         </div>
//                     ))}
//                 </div> */}
//             </div>
//         </div>
//     );
// };

// export default Products;