import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useEstimateCart } from '../context/EstimateCartContext';

type CartItem = {
  id: number;
  quantity: number;
  // ... add any other properties that each cart item would have
};

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { cartItems } = useEstimateCart(); // Ensure this hook returns the correct type

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const handleNavigate = (path: string) => {
    navigate(path);
    setIsMobileMenuOpen(false);
  };

 // Cart Icon and Quantity for Mobile and Desktop
 const renderCartIcon = () => (
  <div className="relative cursor-pointer" onClick={() => navigate('/Quote')}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h18l-2 14H5L3 3z" />
      </svg>
      {cartItems.length > 0 && (
          <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs px-2 py-1 rounded-full">
              {getTotalItems()}
          </span>
      )}
  </div>
);

  // Function to calculate the total quantity of items in the cart
  const getTotalItems = () => cartItems.reduce((total: number, item: CartItem) => total + (item.quantity || 1), 0);

  return (
    <nav className="bg-gray-800 text-white p-4 relative z-50">
        <div className="container mx-auto flex justify-between items-center">
            <Link to="/" className="text-xl font-bold hover:text-custom-yellow">Oasis Lighting Design</Link>

            {/* Mobile Menu and Cart */}
            <div className="md:hidden flex items-center">
                {/* Cart Icon */}
                {renderCartIcon()}

                {/* Hamburger Menu */}
                <button onClick={toggleMobileMenu} className="ml-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hover:text-custom-yellow" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMobileMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'} />
                    </svg>
                </button>
            </div>

            {/* Mobile Menu */}
            <div className={`absolute top-full right-0 mt-1 bg-gray-800 w-full md:w-auto md:hidden ${isMobileMenuOpen ? 'block' : 'hidden'} shadow-lg rounded-b-lg`}>
                <div className="p-4">
                    {/* Mobile Menu Links */}
                    <Link to="/products" className="block hover:text-custom-yellow transition duration-300 px-4 py-2" onClick={toggleMobileMenu}>Products</Link>
                    <Link to="/services" className="block hover:text-custom-yellow transition duration-300 px-4 py-2" onClick={toggleMobileMenu}>Services</Link>
                    <Link to="/areas" className="block hover:text-custom-yellow transition duration-300 px-4 py-2" onClick={toggleMobileMenu}>Areas We Service</Link>
                    <Link to="/aboutussection" className="block hover:text-custom-yellow transition duration-300 px-4 py-2" onClick={toggleMobileMenu}>About Us</Link>
                    <Link to="/contact" className="block hover:text-custom-yellow transition duration-300 px-4 py-2" onClick={toggleMobileMenu}>Contact</Link>
                </div>
            </div>

            {/* Desktop Menu */}
            <div className="hidden md:flex space-x-4">
                {/* Desktop Menu Links */}
                <Link to="/services" className="text-white hover:text-custom-yellow">Services</Link>
                <Link to="/products" className="text-white hover:text-custom-yellow">Products</Link>
                <Link to="/areas" className="text-white hover:text-custom-yellow">Areas We Service</Link>
                <Link to="/aboutussection" className="text-white hover:text-custom-yellow">About Us</Link>
                <Link to="/contact" className="text-white hover:text-custom-yellow">Contact</Link>
                {/* Render cart icon for desktop */}
                {renderCartIcon()}
            </div>
        </div>
    </nav>
);
};

export default Navbar;




// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';

// const Navbar: React.FC = () => {
//   const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);
//   const navigate = useNavigate();

//   const categories = [
//     { name: 'View All', query: '' },
//     { name: 'Outdoor Wall Lights', query: 'category=Outdoor%20Wall%20Lights' },
//     { name: 'Post Lights', query: 'category=Post%20Lights' },
//     { name: 'Pathway Lights', query: 'category=Pathway%20Lights' },
//     { name: 'Landscape Lighting', query: 'category=Landscape%20Lighting' },
//     { name: 'Step Lighting', query: 'category=Step%20Lighting' },
//     { name: 'Landscape Accessories', query: 'category=Landscape%20Accessories' },
//     { name: 'Outdoor String Lights', query: 'category=Outdoor%20String%20Lights' },
//   ];

//   const handleNavigate = (path: string) => {
//     navigate(path);
//     setIsProductsDropdownOpen(false);
//   };

//   return (
//     <nav className="bg-gray-800 text-white p-4">
//       <div className="container mx-auto flex justify-between items-center">
//         <Link to="/" className="text-xl font-bold">Oasis Landscape Lighting</Link>
//         <div className="flex space-x-4 items-center relative">
//           <button onClick={() => handleNavigate('/products')} className="hover:text-gray-400 transition duration-300">
//             Products
//           </button>
//           <div className="relative">
//             <button 
//               onClick={() => setIsProductsDropdownOpen(!isProductsDropdownOpen)}
//               className="hover:text-gray-400 transition duration-300"
//             >▼</button>
//             {isProductsDropdownOpen && (
//               <div 
//                 onMouseLeave={() => setIsProductsDropdownOpen(false)}
//                 className="absolute mt-1 py-2 w-48 bg-white rounded-md shadow-xl z-10"
//               >
//                 {categories.map(({ name, query }) => (
//                   <button
//                     key={name}
//                     onClick={() => handleNavigate(query ? `/products?${query}` : '/products')}
//                     className="block px-4 py-2 text-sm text-gray-800 hover:bg-blue-500 hover:text-white w-full text-left"
//                   >
//                     {name}
//                   </button>
//                 ))}
//               </div>
//             )}
//           </div>
//           <Link to="/services" className="hover:text-gray-400 transition duration-300">Services</Link>
//           <Link to="/areas" className="hover:text-gray-400 transition duration-300">Areas We Service</Link>
//           <Link to="/AboutUs" className="hover:text-gray-400 transition duration-300">About Us</Link>
//           <Link to="/Contact" className="hover:text-gray-400 transition duration-300">Contact</Link> {/* Corrected this line */}
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;



// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';

// const Navbar: React.FC = () => {
//   const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);
//   const navigate = useNavigate();

//   const categories = [
//     { name: 'View All', query: '' },
//     { name: 'Outdoor Wall Lights', query: 'category=Outdoor%20Wall%20Lights' },
//     { name: 'Post Lights', query: 'category=Post%20Lights' },
//     { name: 'Pathway Lights', query: 'category=Pathway%20Lights' },
//     { name: 'Landscape Lighting', query: 'category=Landscape%20Lighting' },
//     { name: 'Step Lighting', query: 'category=Step%20Lighting' },
//     { name: 'Landscape Accessories', query: 'category=Landscape%20Accessories' },
//     { name: 'Outdoor String Lights', query: 'category=Outdoor%20String%20Lights' },
//   ];

//   const handleNavigate = (path: string) => {
//     navigate(path);
//     setIsProductsDropdownOpen(false);
//   };

//   return (
//     <nav className="bg-gray-800 text-white p-4">
//       <div className="container mx-auto flex justify-between items-center">
//         <Link to="/" className="text-xl font-bold">Landscape Lighting</Link>
//         <div className="flex space-x-4 items-center relative">
//           {/* Separate button for navigating to the products page */}
//           <button onClick={() => handleNavigate('/products')} className="hover:text-gray-400 transition duration-300">
//             Products
//           </button>
//           {/* Icon or smaller button to trigger dropdown */}
//           <div className="relative">
//             <button 
//               onClick={() => setIsProductsDropdownOpen(!isProductsDropdownOpen)}
//               className="hover:text-gray-400 transition duration-300"
//             >▼</button>
//             {isProductsDropdownOpen && (
//               <div 
//                 onMouseLeave={() => setIsProductsDropdownOpen(false)}
//                 className="absolute mt-1 py-2 w-48 bg-white rounded-md shadow-xl z-10"
//               >
//                 {categories.map(({ name, query }) => (
//                   <button
//                     key={name}
//                     onClick={() => handleNavigate(query ? `/products?${query}` : '/products')}
//                     className="block px-4 py-2 text-sm text-gray-800 hover:bg-blue-500 hover:text-white w-full text-left"
//                   >
//                     {name}
//                   </button>
//                 ))}
//               </div>
//             )}
//           </div>
//           <Link to="/AboutUs" className="hover:text-gray-400 transition duration-300">About Us</Link>
//           {/* <Link to="/contact" className="hover:text-gray-400 transition duration-300">Contact</Link> */}
//           <Link to="/services" className="hover:text-gray-400 transition duration-300">Services</Link>
//           <Link to="/contact" element={<Contact />} />

          
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;
