// src/components/FlipCard.tsx
import React from 'react';

interface FlipCardProps {
  icon: string; // Path to the icon for the front
  title: string;
  details: string; // Details to display on the back
}

const FlipCard: React.FC<FlipCardProps> = ({ icon, title, details }) => {
  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front" title={title}>
          <img src={icon} alt="Landscape Lighting" />
          {/* <div className="flip-card-front-text absolute drop-shadow-2xl text-white text-5xl font-bold">
            {title} {/* This will now appear on top of the image */}
          {/* </div> */}
          <div className="absolute bottom-0 left-0 right-0 bg-gray-700 bg-opacity-75 text-white p-4 group-hover:bg-opacity-100 transition-opacity round">
            <p className="text-lg font-bold">{title}</p>
            {/* <p className="text-sm">Your service description here...</p> */}
          </div>
        </div>
        {/* <div className="flip-card-back border rounded-lg p-4 shadow-lg hover:shadow-custom-yellow">
          <p>{details}</p>
        </div> */}
      </div>
    </div>
  );
};

export default FlipCard;
