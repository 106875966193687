// src/components/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
  const navigate = useNavigate(); // Correctly placed inside the component

  return (
    <footer className="bg-gray-800 text-white">
      {/* Free Estimate Section */}
      <div className="container mx-auto px-4 py-4 text-center">
        <button
          className="bg-black hover:bg-black text-custom-yellow hover:text-custom-yellow shadow-lg shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
          onClick={() => navigate('/quote')}>
          GET YOUR FREE ESTIMATE
        </button>
        
      </div>
      <div className="container mx-auto px-4 py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ">
        
        {/* Customer Service */}
        <div>
          <h3 className="font-bold text-lg mb-3 hover:text-custom-yellow">Customer Service</h3>
          <ul>
            <li><Link to="/contact" className="hover:underline hover:text-custom-yellow">Contact Us</Link></li>
            <li><a href="/return-policy" className="hover:underline hover:text-custom-yellow">Returns</a></li>
            <li><a href="/shipping" className="hover:underline hover:text-custom-yellow">Shipping Policy</a></li>
            <li><a href="/subscribe" className="hover:underline hover:text-custom-yellow">Subscribe</a></li>
            {/* ... add more customer service links as needed */}
          </ul>
        </div>

        {/* Services */}
        <div>
          <h3 className="font-bold text-lg mb-3 hover:text-custom-yellow">Services</h3>
          <ul>
            <li className=" hover:text-custom-yellow">Spotlights & Flood Lights</li>
            <li className="hover:underline hover:text-custom-yellow">Walkway Lights</li>
            <li className="hover:underline hover:text-custom-yellow">Hardscape & Masonry</li>
            <li className="hover:underline hover:text-custom-yellow">In-ground Well Lights</li>
            <li className="hover:underline hover:text-custom-yellow">Indicator Lights</li>
            <li className="hover:underline hover:text-custom-yellow">Outdoor LED Tape</li>
            <li className="hover:underline hover:text-custom-yellow">Ceiling and Pendants</li>
            <li className="hover:underline hover:text-custom-yellow">Wall Mount</li>
            <li className="hover:underline hover:text-custom-yellow">Color Changing</li>
            {/* ... add more services as needed */}
          </ul>
        </div>

        {/* Company Info */}
        <div>
          <h3 className="font-bold text-lg mb-3 hover:text-custom-yellow">Company Info</h3>
          <ul>
            <li className="hover:underline hover:text-custom-yellow">About Us</li>
            <li className="hover:underline hover:text-custom-yellow">Careers</li>
            <li className="hover:underline hover:text-custom-yellow">Site Map</li>
            {/* ... add more company info links as needed */}
          </ul>
        </div>

        {/* Privacy */}
        <div>
          <h3 className="font-bold text-lg mb-3 hover:text-custom-yellow">Privacy</h3>
          <ul>
            <li className="hover:underline hover:text-custom-yellow">Terms of Use</li>
            <li className="hover:underline hover:text-custom-yellow">Privacy Policy</li>
            {/* ... add more privacy links as needed */}
          </ul>
          
        </div>
      </div>

      <div className="container mx-auto px-4 py-4 text-center">
      <button
          className="mt-4 text-lg bg-black hover:bg-black text-custom-yellow hover:text-custom-yellow shadow-lg shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
          onClick={() =>('/quote')}>
          (631) 306-4602
        </button>
        </div>

      

      {/* Social Media Section */}
      <div className="social-media-section py-4 mt-4 border-t border-gray-700">
        <div className="container mx-auto px-4 flex justify-center">
          
          <a href="https://www.facebook.com" className="mx-2 hover:text-custom-yellow"><FontAwesomeIcon icon={faFacebookF} size="lg" /></a>
          <a href="https://www.twitter.com" className="mx-2 hover:text-custom-yellow"><FontAwesomeIcon icon={faTwitter} size="lg" /></a>
          <a href="https://www.instagram.com" className="mx-2 hover:text-custom-yellow"><FontAwesomeIcon icon={faInstagram} size="lg" /></a>
          <a href="https://www.tiktok.com" className="mx-2 hover:text-custom-yellow"><FontAwesomeIcon icon={faTiktok} size="lg" /></a>
        </div>
      </div>

      <div className="text-center py-4 hover:text-custom-yellow">
        <p>© {new Date().getFullYear()} Oasis Lighting Design. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
