import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AboutUs: React.FC = () => {
    const [offset, setOffset] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    // Calculate the background color based on the scroll offset
    const bgColor = offset > 350 ? `rgba(0, 0, 0, 1)` : `rgba(255, 255, 255, ${1 - Math.min(1, offset / 700)})`;

    // Change text color based on scroll position
    const textColor = offset > 350 ? 'text-white' : 'text-gray-800';

    return (
        <section style={{ backgroundColor: bgColor }} className="py-12 transition-all duration-500 ease-in-out">
            <div className="text-center mb-12">
                <button
                    className="mt-1 bg-blue-700 hover:bg-black text-white hover:text-custom-yellow shadow-lg hover:shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
                    onClick={() => navigate('/quote')}>
                    GET YOUR FREE ESTIMATE
                </button>
                
            </div>
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap items-center">
                    <div className={`lg:w-1/2 px-4 mb-8 lg:mb-0 ${textColor}`}>
                        <h2 className="text-3xl font-bold mb-4">About Oasis Lighting & AV</h2>
                        <p className="mb-4">
                            Discover the expertise of Oasis Lighting & AV, the premier provider of landscape lighting, audio, and home automation solutions in Suffolk County and Nassau County, Long Island. We've been dedicated to illuminating outdoor spaces with innovative designs and top-quality craftsmanship, enhancing the beauty and security of residential and commercial properties.
                        </p>
                        <p>
                            Our mission is to exceed expectations with unparalleled customer service and transformative lighting solutions. Trust Oasis Lighting & AV to create enchanting outdoor oases that go beyond your imagination.
                        </p>
                    </div>
                    <div className="lg:w-1/2 px-4">
                        <img src="/images/oasis.png" alt="Oasis Lighting & AV" className="rounded-lg shadow-lg" />
                    </div>
                    
                </div>
                <button
          className="mt-4 text-lg bg-black hover:bg-black text-custom-yellow hover:text-custom-yellow shadow-lg shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
          onClick={() =>('/quote')}>
          (631) 306-4602
        </button>
            </div>
            
        </section>
    );
};

export default AboutUs;



// import React, { useState, useEffect } from 'react';

// const AboutUs: React.FC = () => {
//     const [offset, setOffset] = useState(0);

//     useEffect(() => {
//         const onScroll = () => setOffset(window.pageYOffset);
//         // Attach the event listener to window scroll event
//         window.addEventListener('scroll', onScroll);
        
//         // Remove the event listener
//         return () => window.removeEventListener('scroll', onScroll);
//     }, []);

//     // Calculate the background color based on the scroll offset
//     // Start with white and transition to black
//     const bgColor = offset > 700
//         ? `rgba(0, 0, 0, 1)` // End color (black)
//         : `rgba(255, 255, 255, ${1 - Math.min(1, offset / 700)})`; // Interpolated color (white to black)

//     return (
//         <section style={{ backgroundColor: bgColor }} className="py-12 transition-colors duration-500">
//             <div className="container mx-auto px-4">
//                 <div className="flex flex-wrap items-center">
//                     <div className="lg:w-1/2 px-4 mb-8 lg:mb-0">
//                         <h2 className="text-3xl font-bold text-gray-800 mb-4">About Landscape Lighting & AV</h2>
//                         <p className="text-gray-600 mb-4">
//                             Since our inception in 2005, Landscape Lighting & AV has been illuminating the outdoor spaces of homes and businesses alike. Our dedicated team of designers and technicians specialize in bringing your outdoor lighting dreams to life, enhancing the beauty and security of your property.
//                         </p>
//                         <p className="text-gray-600">
//                             We pride ourselves on our innovative designs, quality craftsmanship, and unparalleled customer service. Our mission is to create lighting solutions that not only meet but exceed your expectations, transforming your outdoor spaces into enchanting oases.
//                         </p>
//                     </div>
//                     <div className="lg:w-1/2 px-4">
//                         <img src="/images/oasis.png" alt="About Us" className="rounded-lg shadow-lg" />
//                     </div>
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default AboutUs;
