import React from 'react';

// Example brands data - replace with actual brand logo paths and links
const brandsData = [
    { logo: '/icons/amazon.png', name: 'Amazon', link: '#!' },
    { logo: '/icons/apple.png', name: 'Apple', link: '#!' },
    { logo: '/icons/Haven.jpg', name: 'Haven', link: '#!' },
    { logo: '/icons/hinkley.jpg', name: 'Hinkley', link: '#!' },
    { logo: '/icons/kichler.jpg', name: 'Kichler', link: '#!' },
    { logo: '/icons/Lumien.png', name: 'Lumien', link: '#!' },
    { logo: '/icons/roku.png', name: 'Roku', link: '#!' },
    { logo: '/icons/samsung.jpg', name: 'Samsung', link: '#!' },
    { logo: '/icons/sonance.png', name: 'Sonance', link: '#!' },
    { logo: '/icons/sonos.png', name: 'Sonos', link: '#!' },
    { logo: '/icons/ubiquiti.png', name: 'Ubiquiti', link: '#!' },
    { logo: '/icons/volt.png', name: 'Volt', link: '#!' },
    { logo: '/icons/wac.png', name: 'WAC', link: '#!' },
];

const Brands2: React.FC = () => {
    return (
        <section className="py-12 bg-gray-50">
            <div className="container mx-auto px-4">
                <div className="text-center mb-8">
                    <h2 className="text-3xl font-bold text-gray-800">Our Brands</h2>
                    <p className="text-gray-600 mt-2">
                        Explore the top brands we partner with to bring you the best products.
                    </p>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-8">
                    {brandsData.map((brand) => (
                        <a href={brand.link} key={brand.name} className="flex justify-center items-center p-4 hover:scale-105 transform transition-transform duration-300 ease-in-out bg-white shadow-sm rounded-lg">
                            <img src={brand.logo} alt={brand.name} className="h-12 w-auto" />
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Brands2;

//
