// src/components/Contact.tsx
import React, { useState } from 'react';

const Contact: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Here, you'd typically handle form submission, like sending data to your backend or an email service.
        console.log(formData);
        alert('Thank you for reaching out. We will get back to you shortly.');
        // Reset form after submission
        setFormData({
            name: '',
            email: '',
            message: '',
        });
    };

    return (
        <div className="container mx-auto p-8">
            <h1 className="bg-black hover:bg-black mb-4 text-custom-yellow hover:text-custom-yellow shadow-lg shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-30 text-center">Section is under construction - Submit form not operating yet</h1>
            <h2 className="text-2xl font-bold text-center mb-4">Contact Us</h2>
            <p className="text-lg text-center mb-8">We're here to help and answer any question you might have. We look forward to hearing from you.</p>

            <div className="grid md:grid-cols-2 gap-8">
                {/* Contact Form */}
                <div className="border rounded-lg p-8 shadow-lg">
                    <h2 className="text-xl font-semibold mb-4">Send a Message</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-sm font-medium mb-2">Your Name</label>
                            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required className="w-full p-2 border border-gray-300 rounded" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-sm font-medium mb-2">Your Email</label>
                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required className="w-full p-2 border border-gray-300 rounded" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="message" className="block text-sm font-medium mb-2">Message</label>
                            <textarea id="message" name="message" value={formData.message} onChange={handleChange} required rows={4} className="w-full p-2 border border-gray-300 rounded"></textarea>
                        </div>
                        {/* <button type="submit" className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700">Send Message</button> */}
                    </form>
                </div>

                {/* Contact Information */}
                <div>
                    <h2 className="text-xl font-semibold mb-4">Contact Information</h2>
                    <p className="mb-2"><strong>Email:</strong> sales@oasislightingdesign.com</p>
                    <p className="mb-2"><strong>Phone:</strong> (631) 306-4602</p>
                    <p className="mb-2"><strong>Address:</strong> Long Island, NY</p>

                    {/* Optionally, include a map or additional details here */}
                </div>
            </div>
        </div>
    );
};

export default Contact;
