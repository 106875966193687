import React from 'react';

// Example brands data - replace with actual brand logo paths and links
const brandsData = [
    { logo: '/images/adi.png', name: 'ADI', link: '#!' },
    { logo: '/images/b&h.png', name: 'B&H Photo', link: '#!' },
    { logo: '/images/dsg.jpg', name: 'DSG Distributors', link: '#!' },
    { logo: '/images/jackson2.jpg', name: 'Jackson Lighting Supply', link: '#!' },
    { logo: '/images/micro2.svg', name: 'Micro Center', link: '#!' },
    { logo: '/images/nselectric3.png', name: 'N&S Electric', link: '#!' },
    { logo: '/images/proact.png', name: 'Proact Sales', link: '#!' },
    { logo: '/images/Wave.webp', name: 'Wave Electronics', link: '#!' },
];

const Distributors: React.FC = () => {
    return (
        <section className="py-12 bg-gradient-to-tl from-slate-50 to-teal-50">
            <div className="container mx-auto px-4">
                <div className="text-center mb-8">
                    <h2 className="text-3xl font-bold text-gray-800">Distributors</h2>
                    <p className="text-gray-600 mt-2">
                        Top distributors we partner with to bring you the best pricing.
                    </p>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-2 gap-2">
                    {brandsData.map((brand) => (
                        <a href={brand.link} key={brand.name} className="flex justify-center items-center p-4 hover:scale-105 transform transition-transform duration-300 ease-in-out bg-white rounded-lg hover:shadow-custom-yellow shadow-lg focus:shadow-outline">
                            <img src={brand.logo} alt={brand.name} className="h-12 w-auto" />
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Distributors;

//
