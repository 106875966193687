// src/components/ServiceCard2.tsx
import React from 'react';

interface ServiceCard2Props {
    icon: string; // Path to the service icon or image
    title: string; // Service title
    description: string; // Short description of the service
}

const ServiceCard2: React.FC<ServiceCard2Props> = ({ icon, title, description }) => {
    return (
        <div className="cursor-pointerborder outline outline-offset-2 outline-slate-200 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 hover:shadow-custom-yellow">
            {/* Adjusting the image size */}
            <div style={{ height: '200px', overflow: 'hidden' }}> {/* Increase height as needed */}
                <img src={icon} alt={title} className="w-full h-full object-cover object-center" />
            </div>
            <div className="p-4">
                <h3 className="font-bold text-xl mb-2">{title}</h3>
                <p className="text-gray-600">{description}</p>
            </div>
        </div>
    );
};

export default ServiceCard2;
