// src/components/Areas.tsx
import React from 'react';

// Define your areas with counties as keys and towns as values in arrays
const areas = {
  "Nassau County": [
    "Atlantic Beach", "Baldwin", "Bellerose Terrace", "Bellmore", "Bethpage",
       "Cedarhurst", "East Meadow", "East Rockaway", "Elmont", "Floral Park", 
       "Franklin Square", "Freeport", "Garden City", "Hempstead", "Hewlett",
       "Inwood", "Island Park", "Lawrence", "Levittown", "Lynbrook",
        "Malverne", "Merrick", "Mineola", "New Hyde Park", "North Lynbrook",
        "North Valley Stream", "North Woodmere", "Oceanside", "Point Lookout",
        "Rockville Centre", "Roosevelt", "Seaford", "South Hempstead", "Uniondale",
      "Valley Stream", "Wantagh", "West Hempstead", "Woodmere"
  ],
  "Town of North Hempstead": [
    "Albertson", "Carle Place", "Floral Park", "Glenwood Landing", "Great Neck",
    "Greenvale", "Kings Point", "Manhasset", "Mineola", "New Hyde Park",
    "North New Hyde Park", "Old Westbury", "Port Washington", "Roslyn",
    "Roslyn Heights", "Sands Point", "Westbury", "Williston Park"
  ],
  "Town of Oyster Bay": [
    "Bayville", "Bethpage", "Brookville", "East Norwich", "Farmingdale",
    "Glen Head", "Glenwood Landing", "Greenvale", "Hicksville", "Jericho",
    "Locust Valley", "Massapequa", "Massapequa Park", "Mill Neck", "Old Bethpage",
    "Old Westbury", "Oyster Bay", "Plainview", "Sea Cliff", "Syosset",
    "Woodbury"
  ],

  "Town of Babylon": [
    "Amityville", "Babylon", "Copiague", "Deer Park", "Lindenhurst​", "North Amityville", "North Babylon", "West Babylon", "Wyandanch"
  ],

  "Town of Brookhaven": [
    "Bellport", "Blue Point", "Brookhaven", "Calverton", "Centereach", "Center Moriches", "Coram", "East Moriches", "East Patchogue",
    "Eastport", "East Setauket", "Farmingville", "Holbrook", "Holtsville", "Lake Grove",
    "Manorville", "Mastic", "Mastic Beach", "Medford", "Middle Island", "Miller Place",
    "Moriches", "Mount Sinai", "North Patchogue", "Patchogue", "Port Jefferson",
    "Port Jefferson Station", "Ridge", "Rocky Point", "Ronkonkoma", "Selden",
    "Shirley", "Shoreham", "Sound Beach", "Stony Brook", "Upton", "Wading River", "Yaphank"
],


  "Town of East Hampton" : [
    "Amagansett",
    "East Hampton",
    "Montauk",
    "Sag Harbor",
    "Wainscott",
 ],

 "Town of Huntington" : [
 "Centerport",
 "Cold Spring Harbor",
 "COMMACK NY",
 "East Northport",
 "Greenlawn",
 "Huntington",
 "Huntington Station",
 "Lloyd Harbor",
 "Melville",
 "Northport",
 ],

 "Town of Islip": [
    "Bayport", "Bay Shore", "Bohemia", "Brentwood", "Brightwaters",
    "Central Islip", "East Islip", "Great River", "Hauppauge", "Holbrook",
    "Holtsville", "Islandia", "Islip", "Islip Terrace", "Oakdale",
    "Ocean Beach", "Ronkonkoma", "Sayville", "West Islip", "West Sayville"
],
"Town of Riverhead": [
    "Aquebogue", "Calverton", "Jamesport", "Laurel", "Manorville",
    "Riverhead", "South Jamesport", "Wading River"
],
"Town of Shelter Island": [
    "Shelter Island", "Shelter Island Heights"
],
"Town of Smithtown": [
    "Commack", "Hauppauge", "Kings Park", "Nesconset", "Saint James",
    "Smithtown"
],
"Town of Southhampton": [
    "Bridgehampton", "Eastport", "East Quogue", "Hampton Bays", "Quogue",
    "Remsenburg", "Sagaponack", "Sag Harbor", "Southampton", "Speonk",
    "Water Mill", "Westhampton", "Westhampton Beach"
],
"Town of Southold": [
    "Cutchogue", "East Marion", "Fishers Island", "Greenport", "Laurel",
    "Mattituck", "New Suffolk", "Orient", "Peconic", "Southold"
],

  // ... more counties and their towns
};

const Areas: React.FC = () => {
  return (
    <div className="relative text-center py-12">
      {/* Background images */}
      <div className="absolute inset-0">
        {/* Image for mobile */}
        <img
          src="/icons/bg3.webp" // Path to your alternative mobile image
          alt="Long Island, NY"
          className="w-full h-full object-cover lg:hidden"
        />
        {/* Image for larger screens */}
        <img
          src="/icons/areas_bg.jpg" // Path to your default image
          alt="Long Island, NY"
          className="hidden lg:block w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      {/* Content */}
      <div className="relative container mx-auto px-8">

      <div className="container mx-auto px-4 py-4 text-center">
      <button
          className="mt-4 mb-5 text-lg bg-black hover:bg-black text-custom-yellow hover:text-custom-yellow shadow-lg shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
          onClick={() =>('/quote')}>
          (631) 306-4602
        </button>
        </div>


        <h1 className="text-2xl font-bold text-white mb-8">Areas We Service</h1>

        
        <div className="flex flex-wrap justify-center text-white">
          {Object.entries(areas).map(([county, towns]) => (
            <div key={county} className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-4">
              <h2 className="text-lg font-bold mb-2 hover:text-custom-yellow">{county}</h2>
              <div className="bg-white bg-opacity-10 rounded-lg p-4">
                {towns.map(town => (
                  <span key={town} className="inline-block bg-blue-100 bg-opacity-20 hover:bg-opacity-30 text-xs font-semibold m-1 px-2.5 py-0.5 rounded hover:text-custom-yellow">
                    {town}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Areas;