import React, { useState } from 'react';
import { useEstimateCart } from '../context/EstimateCartContext';
import axios from 'axios';


const Quote = () => {
  const [formData, setFormData] = useState({
    address: '',
    service: [] as string[],
    completionTimeframe: '',
    name: '',
    email: '',
    phone: '',
    description: '',
  });

  const { cartItems } = useEstimateCart();
  type CartItem = {
    id: number;
    name: string;
    quantity: number;
    image: string; // Add this line to include the image URL
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // This should only be called once
    console.log('Form data submitted:', formData);
  
    try {
      const response = await axios.post('/api/send-quote', formData); // Make sure this endpoint matches your server's configuration
      console.log(response.data);
      alert('Your quote request has been sent!');
    } catch (error) {
      console.error('Error sending quote request:', error);
      alert('There was an error sending your request. Please try again.');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    let updatedServices = [...formData.service];
    if (event.target.type === 'checkbox') {
      const isChecked = (event.target as HTMLInputElement).checked;
      if (isChecked) {
        updatedServices = [...formData.service, value];
      } else {
        updatedServices = formData.service.filter((service) => service !== value);
      }
    } else {
      setFormData({ ...formData, [name]: value });
      return;
    }
    setFormData({ ...formData, service: updatedServices });
  };


  return (
    <div className="container mx-auto p-8 ">

      <h1 className="bg-black hover:bg-black mb-4 text-custom-yellow hover:text-custom-yellow shadow-lg shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-30 text-center">Section is under construction - Submit form not operating yet</h1>
      <div className="max-w-2xl mx-auto  bg-white p-6 rounded shadow border-4 bg-gradient-to-r from-slate-900 to-slate-700">


        <div className="mb-4">
          <label htmlFor="name" className="block text-custom-yellow rounded text-sm font-bold mb-2">
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your full name"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-custom-yellow text-sm font-bold mb-2">
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your email address"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="phone" className="block text-custom-yellow text-sm font-bold mb-2">
            Phone Number:
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your phone number"
          />

          {/* Form and other inputs */}
          <label htmlFor="service" className="block text-custom-yellow text-sm font-bold mb-2">Services Requesting:</label>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Hardcoded service options */}
            <div className="card">
              <label className="flex items-center space-x-3">
                <input type="checkbox" name="service" value="Landscape Lighting" checked={formData.service.includes("Landscape Lighting")} onChange={handleInputChange} className="form-checkbox h-5 w-5 text-blue-600" />
                <span className="text-custom-yellow">Landscape Lighting</span>
              </label>
            </div>
            {/* Repeat for other services */}
          </div>
          {/* Displaying selected items */}
          <div className="container mx-auto p-8">
            {/* Form and service checkboxes */}

            {/* Displaying selected items with images */}
            <div className="shadow-lg shadow-custom-yellow text-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300">
              <h3>Items for Your Estimate:</h3>
              <ul>
                {cartItems.map((item: CartItem) => (
                  <li key={item.id} className="flex items-center mb-4">
                    {/* Add a class for styling image container */}
                    <div className="image-container mr-8">
                      <img src={item.image} alt={item.name} className="w-16 h-16" />
                    </div>
                    <div className="text-container flex-1"> {/* Add flex-1 to make text take up remaining space */}
                      <span>{item.name} - Quantity: {item.quantity}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="container mx-auto p-8">
              {/* ... */}
              <form onSubmit={handleSubmit}>
                {/* ... rest of your form elements ... */}
                {/* Submit button */}
                <div className="text-center mt-4">
                  {/* <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Submit Request
                  </button> */}
                </div>
              </form>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

export default Quote;


// // src/components/Quote.tsx
// import React, { useState } from 'react';
// import { Product } from '../types'; // Ensure this path is correct and the type matches your product structure
// import productsData from '../data/products.json'; // Ensure this path is correct
// import { useEstimateCart } from '../context/EstimateCartContext';

// const Quote = () => {
//   const [formData, setFormData] = useState({
//     address: '',
//     service: [] as string[],
//     completionTimeframe: '',
//     name: '',
//     email: '',
//     phone: '',
//     description: '',
//   });

//   const { getItems } = useEstimateCart();
//   const cartItems = getItems();
//   type CartItem = {
//     id: number;
//     name: string;
//     quantity: number;

//   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
//     const { name, value } = event.target;
//     if (event.target.type === 'checkbox') {
//       // Correctly typecast the target to HTMLInputElement for checkboxes
//       const target = event.target as HTMLInputElement;
//       setFormData(prevData => ({
//         ...prevData,
//         service: target.checked
//           ? [...prevData.service, value]
//           : prevData.service.filter(s => s !== value),
//       }));
//     } else {
//       setFormData(prevData => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }
//   };

//   const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     console.log('Form data submitted:', formData);
//     // Additional form submission logic goes here
//   };

//   return (
//     <div className="container mx-auto p-8">
//       <div className="max-w-2xl mx-auto bg-white p-6 rounded shadow">
//         <form onSubmit={handleSubmit}>
//           {/* Rest of the form elements go here */}
//           {/* ... */}
//           <div className="mb-4">
//             {/* Name input */}
//             <div className="mb-4">
//               <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
//                 Name:
//               </label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleInputChange}
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                 placeholder="Your full name"
//               />
//             </div>
//             {/* Email input */}
//             <div className="mb-4">
//               <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
//                 Email:
//               </label>
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleInputChange}
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                 placeholder="Your email address"
//               />
//             </div>


//             {/* Phone input */}
//             <div className="mb-4">
//               <label htmlFor="phone" className="block text-gray-700 text-sm font-bold mb-2">
//                 Phone Number:
//               </label>
//               <input
//                 type="tel"
//                 id="phone"
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handleInputChange}
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                 placeholder="Your phone number"
//               />
//             </div>

//             {/* Description textarea */}
//             <div className="mb-4">
//               <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">
//                 Description:
//               </label>
//               <textarea
//                 id="description"
//                 name="description"
//                 value={formData.description}
//                 onChange={handleInputChange}
//                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                 placeholder="Scope of work you're looking to get done"
//               ></textarea>
//             </div>

//             <label htmlFor="service" className="block text-gray-700 text-sm font-bold mb-2">
//               Services:
//             </label>
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//               <div className="card">
//                 <label className="flex items-center space-x-3">
//                   <input
//                     type="checkbox"
//                     name="service"
//                     value="Landscape Lighting"
//                     checked={formData.service.includes("Landscape Lighting")}
//                     onChange={handleInputChange}
//                     className="form-checkbox h-5 w-5 text-blue-600"
//                   /><span className="text-gray-700">Landscape Lighting</span>
//                 </label>
//               </div>
//               <div className="card">
//                 <label className="flex items-center space-x-3">
//                   <input
//                     type="checkbox"
//                     name="service"
//                     value="Interior Lighting"
//                     checked={formData.service.includes("Interior Lighting")}
//                     onChange={handleInputChange}
//                     className="form-checkbox h-5 w-5 text-blue-600"
//                   /><span className="text-gray-700">Interior Lighting</span>
//                 </label>
//               </div>
//               {/* Add more service options in the same pattern */}
//               <div>
//         <h3>Items for Your Estimate:</h3>
//         <ul>
//           {cartItems.map((item) => (
//             <li key={item.id}>
//               {item.name} - Quantity: {item.quantity}
//             </li>
//           ))}
//         </ul>
//       </div>
//               {/* ... */}
//             </div>
//           </div>
//           {/* ... */}
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Quote;



// import React, { useState } from 'react';
// import { useEstimateCart } from '../context/EstimateCartContext';
// import axios from 'axios';


// const Quote = () => {
//   const [formData, setFormData] = useState({
//     address: '',
//     service: [] as string[],
//     completionTimeframe: '',
//     name: '',
//     email: '',
//     phone: '',
//     description: '',
//   });

//   const { cartItems } = useEstimateCart();
//   type CartItem = {
//     id: number;
//     name: string;
//     quantity: number;
//     image: string; // Add this line to include the image URL
//   };

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault(); // This should only be called once
//     console.log('Form data submitted:', formData);
  
//     try {
//       const response = await axios.post('http://194.195.92.159/api/send-quote', formData); // Make sure this endpoint matches your server's configuration
//       console.log(response.data);
//       alert('Your quote request has been sent!');
//     } catch (error) {
//       console.error('Error sending quote request:', error);
//       alert('There was an error sending your request. Please try again.');
//     }
//   };

//   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     const { name, value } = event.target;
//     let updatedServices = [...formData.service];
//     if (event.target.type === 'checkbox') {
//       const isChecked = (event.target as HTMLInputElement).checked;
//       if (isChecked) {
//         updatedServices = [...formData.service, value];
//       } else {
//         updatedServices = formData.service.filter((service) => service !== value);
//       }
//     } else {
//       setFormData({ ...formData, [name]: value });
//       return;
//     }
//     setFormData({ ...formData, service: updatedServices });
//   };


//   return (
//     <div className="container mx-auto p-8 ">
//       <div className="max-w-2xl mx-auto  bg-white p-6 rounded shadow border-4 bg-gradient-to-r from-slate-900 to-slate-700">


//         <div className="mb-4">
//           <label htmlFor="name" className="block text-custom-yellow rounded text-sm font-bold mb-2">
//             Name:
//           </label>
//           <input
//             type="text"
//             id="name"
//             name="name"
//             value={formData.name}
//             onChange={handleInputChange}
//             className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//             placeholder="Your full name"
//           />
//         </div>
//         <div className="mb-4">
//           <label htmlFor="email" className="block text-custom-yellow text-sm font-bold mb-2">
//             Email:
//           </label>
//           <input
//             type="email"
//             id="email"
//             name="email"
//             value={formData.email}
//             onChange={handleInputChange}
//             className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//             placeholder="Your email address"
//           />
//         </div>
//         <div className="mb-4">
//           <label htmlFor="phone" className="block text-custom-yellow text-sm font-bold mb-2">
//             Phone Number:
//           </label>
//           <input
//             type="tel"
//             id="phone"
//             name="phone"
//             value={formData.phone}
//             onChange={handleInputChange}
//             className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//             placeholder="Your phone number"
//           />

//           {/* Form and other inputs */}
//           <label htmlFor="service" className="block text-custom-yellow text-sm font-bold mb-2">Services Requesting:</label>
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//             {/* Hardcoded service options */}
//             <div className="card">
//               <label className="flex items-center space-x-3">
//                 <input type="checkbox" name="service" value="Landscape Lighting" checked={formData.service.includes("Landscape Lighting")} onChange={handleInputChange} className="form-checkbox h-5 w-5 text-blue-600" />
//                 <span className="text-custom-yellow">Landscape Lighting</span>
//               </label>
//             </div>
//             {/* Repeat for other services */}
//           </div>
//           {/* Displaying selected items */}
//           <div className="container mx-auto p-8">
//             {/* Form and service checkboxes */}

//             {/* Displaying selected items with images */}
//             <div className="shadow-lg shadow-custom-yellow text-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300">
//               <h3>Items for Your Estimate:</h3>
//               <ul>
//                 {cartItems.map((item: CartItem) => (
//                   <li key={item.id} className="flex items-center mb-4">
//                     {/* Add a class for styling image container */}
//                     <div className="image-container mr-8">
//                       <img src={item.image} alt={item.name} className="w-16 h-16" />
//                     </div>
//                     <div className="text-container flex-1"> {/* Add flex-1 to make text take up remaining space */}
//                       <span>{item.name} - Quantity: {item.quantity}</span>
//                     </div>
//                   </li>
//                 ))}
//               </ul>
//             </div>

//             <div className="container mx-auto p-8">
//               {/* ... */}
//               <form onSubmit={handleSubmit}>
//                 {/* ... rest of your form elements ... */}
//                 {/* Submit button */}
//                 <div className="text-center mt-4">
//                   <button
//                     type="submit"
//                     className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//                   >
//                     Submit Request
//                   </button>
//                 </div>
//               </form>
//             </div>

//           </div>
//         </div>

//       </div>
//     </div>
//   );
// };

// export default Quote;
