// src/components/HeroBanner.tsx
import React from 'react';

const HeroBanner: React.FC = () => {
  return (
    <div className="relative bg-cover bg-center text-white" style={{ backgroundImage: 'url(/images/l1.jpg)' }}>
      <div className="bg-opacity-50 bg-black flex items-center justify-center" style={{ height: '75vh' }}>
        <div className="text-center">
          <h1 className="text-5xl font-bold uppercase">Oasis Lighting Design</h1>
          <p className="mt-4 text-xl">Illuminate your space with elegance and efficiency</p>
          <button
          className="mt-4 text-lg bg-black hover:bg-black text-custom-yellow hover:text-custom-yellow shadow-lg shadow-custom-yellow font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
          onClick={() =>('/quote')}>
          (631) 306-4602
        </button>
          {/* Add any additional buttons/links if needed */}
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
