// src/components/Services2.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ServiceCard2 from './ServiceCard2';

// Example service data, replace icons with actual paths and add descriptions
const serviceData = [
  { icon: '/images/landscape_kit2.jpg', title: 'Landscape Speaker System kits', description: 'Illuminate vast areas or highlight key features with versatile lighting.' },
  { icon: '/images/stake.webp', title: 'Stake Speakers', description: 'Ensure safe and inviting pathways with elegant lighting solutions.' },
  { icon: '/images/landscape_kit.jpg', title: 'Landscape Subwoofers', description: 'Enhance your outdoor architecture with integrated lighting designs.' },
  { icon: '/images/all_weather.jpg', title: 'Surface Mount Speakers', description: 'Guide and inform with discreet yet clear lighting markers.' },
  { icon: '/images/rock.jpg', title: 'Rock Speakers', description: 'Flexible and creative lighting to outline and accentuate features.' },
  { icon: '/images/rack3.jpeg', title: 'Audio Equipment', description: 'Achieve seamless illumination with lights flush with the ground.' },
 
];

const ExteriorAudio: React.FC = () => {
  const navigate = useNavigate(); // Correctly placed inside the component

  return (
    <section className="py-4 bg-white-400">
      <div className="container mx-auto px-4">

      <div className="text-center mb-12 mt-4">
          <div className="text-4xl font-bold"></div>
          <div className="text-gray-600 mt-4"></div>
          <h2 className="text-4xl bg-white mt-7">Exterior Audio</h2>
        </div>





        <div className="grid grid-2 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-2 mb-2">
          {serviceData.map(service => (
            <ServiceCard2 key={service.title} icon={service.icon} title={service.title} description={service.description} />
          ))}
        </div>

        {/* <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-800 mt-6 mb-6">&</h1>

          
          <p className="text-gray-600 mt-6 text-lg">
            Explore our wide range of landscape lighting & AV services designed to bring your outdoor spaces to life.
          </p>
        </div> */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Additional content */}
        </div>
      </div>
    </section>
  );
};

export default ExteriorAudio;
