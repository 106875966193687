// src/components/ProductDetail.tsx
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import products from '../data/products.json';
import { Product } from '../types'; // Adjust the import path as necessary

const ProductDetail: React.FC = () => {
  let { id } = useParams<{ id: string }>();
  const [selectedImage, setSelectedImage] = useState<string>('');

  const product = products.find((p) => p.id.toString() === id) as Product;

  if (!product) {
    return <div className="text-center py-10"><h2 className="text-2xl">Product not found</h2></div>;
  }

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
  }

  return (
    <div className="container mx-auto py-10">
      <div className="md:flex md:items-center md:justify-center">
        <div className="md:w-1/2 lg:w-1/3">
          <img src={selectedImage || product.image} alt={product.name} className="max-w-full h-auto rounded-lg shadow-lg" />
          <div className="flex space-x-2 mt-4">
            {product.images?.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`${product.name} ${index + 1}`}
                className="w-24 h-24 rounded-lg shadow cursor-pointer"
                onClick={() => handleImageClick(img)}
              />
            )) || <p>No additional images available.</p>}
          </div>
        </div>
        <div className="mt-4 md:mt-0 md:w-1/2 lg:w-1/3 md:ml-10">
          <h1 className="text-3xl font-bold mb-3">{product.name}</h1>
          <p className="text-3xl font-bold mb-3">{product.brand}</p>
          <p className="mb-5">{product.description}</p>
          <p className="text-lg font-semibold mb-5">{product.price}</p>
          
          {/* Details */}
          <div className="bg-white p-4 rounded-lg">
            <ul className="text-sm font-medium text-gray-700">
              <li className="flex justify-between py-2">
                <span>IP65 Rating</span> 
                <span className="ml-4 flex-1 border-l-2 border-gray-300 pl-4">{product.IPRating}</span>
              </li>
              <li className="flex justify-between py-2">
                <span>Wattage</span>
                <span className="ml-4 flex-1 border-l-2 border-gray-300 pl-4">6</span>
              </li>
              <li className="flex justify-between py-2">
                <span>Lumens</span>
                <span className="ml-4 flex-1 border-l-2 border-gray-300 pl-4">450</span>
              </li>
              <li className="flex justify-between py-2">
                <span>Temperature</span>
                <span className="ml-4 flex-1 border-l-2 border-gray-300 pl-4">2700K</span>
              </li>
              <li className="flex justify-between py-2">
                <span>Finish</span>
                <span className="ml-4 flex-1 border-l-2 border-gray-300 pl-4">Black</span>
              </li>
              <li className="flex justify-between py-2">
                <span>Construction</span>
                <span className="ml-4 flex-1 border-l-2 border-gray-300 pl-4">Aluminum</span>
              </li>
            </ul>
          </div>

          {product.pdf && (
            <a href={product.pdf} className="text-blue-600 hover:text-blue-800" download>Download Spec Sheet</a>
          )}
          {/* Add to Cart Button and other details */}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;



// // src/components/ProductDetail.tsx
// import React from 'react';
// import { useParams } from 'react-router-dom';
// import products from '../data/products.json';
// import { Product } from '../types'; // Adjust the import path as necessary

// const ProductDetail: React.FC = () => {
//   let { id } = useParams<{ id: string }>();

//   const product = products.find((p) => p.id.toString() === id) as Product;

//   if (!product) {
//     return <div className="text-center py-10"><h2 className="text-2xl">Product not found</h2></div>;
//   }

//   return (
//     <div className="container mx-auto py-10">
//       <div className="md:flex md:items-center md:justify-center">
//         <div className="md:w-1/2 lg:w-1/3">
//           <img src={product.image} alt={product.name} className="max-w-full h-auto rounded-lg shadow-lg" />
//           <div className="flex space-x-2 mt-4">
//             {/* Adjusted mapping with optional chaining and fallback */}
//             {product.images?.map((img, index) => (
//               <img key={index} src={img} alt={`${product.name} ${index + 1}`} className="w-24 h-24 rounded-lg shadow" />
//             )) || <p>No additional images available.</p>}
//           </div>
//         </div>
//         <div className="mt-4 md:mt-0 md:w-1/2 lg:w-1/3 md:ml-10">
//           <h1 className="text-3xl font-bold mb-3">{product.name}</h1>
//           <p className="text-3xl font-bold mb-3">{product.brand}</p>
//           <p className="mb-5">{product.description}</p>
//           <p className="text-lg font-semibold mb-5">{product.price}</p>
          
//           {/* Details */}
//           <div className="bg-white p-4 rounded-lg">
//   <ul className="text-sm font-medium text-gray-700">
//     <li className="flex justify-between py-2">
//       <span>IP65 Rating</span> 
//       <span className="ml-4 flex-1 border-l-2 border-gray-300 pl-4">10 Years Warranty</span>
//     </li>
//     <li className="flex justify-between py-2">
//       <span>Wattage</span>
//       <span className="ml-4 flex-1 border-l-2 border-gray-300 pl-4">6</span>
//     </li>
//     <li className="flex justify-between py-2">
//       <span>Lumens</span>
//       <span className="ml-4 flex-1 border-l-2 border-gray-300 pl-4">450</span>
//     </li>
//     <li className="flex justify-between py-2">
//       <span>Temperature</span>
//       <span className="ml-4 flex-1 border-l-2 border-gray-300 pl-4">2700K</span>
//     </li>
//     <li className="flex justify-between py-2">
//       <span>Finish</span>
//       <span className="ml-4 flex-1 border-l-2 border-gray-300 pl-4">Black</span>
//     </li>
//     <li className="flex justify-between py-2">
//       <span>Construction</span>
//       <span className="ml-4 flex-1 border-l-2 border-gray-300 pl-4">Aluminum</span>
//     </li>
//   </ul>
// </div>


//           {product.pdf && (
//             <a href={product.pdf} className="text-blue-600 hover:text-blue-800" download>Download Spec Sheet</a>
//           )}
//           {/* Add to Cart Button and other details */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductDetail;


