import React, { useState, useEffect } from 'react';

const AboutUsSection: React.FC = () => {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    // Calculate the background color based on the scroll offset
    // Interpolate between white at the start (255, 255, 255) to black (0, 0, 0) at 700px scroll
    const r = Math.max(255 - (offset / 700) * 255, 0);
    const g = Math.max(255 - (offset / 700) * 255, 0);
    const b = Math.max(255 - (offset / 700) * 255, 0);
    const bgColor = `rgba(${r}, ${g}, ${b}, 1)`;

    // Change text color to white after half of the transition to black background
    const textColor = offset > 350 ? 'text-white' : 'text-gray-800';

    return (
        <section style={{ backgroundColor: bgColor }} className="py-12 transition-all duration-500 ease-in-out">
          <div className="container mx-auto px-4">
           <div className="text-center mb-8">
      <h2 className="text-3xl lg:text-4xl font-bold text-gray-800">About Us</h2>
      <p className="mt-4 text-gray-600">
        Welcome to Oasis Lighting Design, Your Premier Lighting and Audio Specialists
      </p>
    </div>

    <div className="mb-10">
      <h3 className="text-2xl text-gray-700 font-semibold">Our Journey</h3>
      <p className="mt-4 text-gray-500">
        Founded by a team of passionate designers and technicians, Oasis Lighting Design began with a simple idea: to transform ordinary spaces into extraordinary experiences...
      </p>
    </div>

    <div className="mb-10">
      <h3 className="text-2xl text-gray-700 font-semibold">Our Services</h3>
      <ul className="list-disc space-y-2 mt-4 text-gray-500 px-5 md:px-10">
        <li>Landscape Lighting: From the soft glow of path lights to the dramatic flair of spotlighting...</li>
        <li>Landscape Audio: Step outside and immerse yourself in a world of pristine sound...</li>
        <li>Interior Lighting: Inside your home or business...</li>
        <li>Interior Audio: Experience the ultimate in-home entertainment...</li>
      </ul>
    </div>

    <div className="mb-10">
      <h3 className="text-2xl text-gray-700 font-semibold">Our Commitment</h3>
      <p className="mt-4 text-gray-500">
        We are committed to excellence in every project we undertake...
      </p>
    </div>

    <div className="text-center">
      <h3 className="text-2xl text-gray-700 font-semibold">Connect With Us</h3>
      <p className="mt-4 text-gray-500">
        If you're ready to transform your space with the magic of light and sound, we invite you to contact us today for a consultation...
      </p>
    </div>
                    <div className="lg:w-1/2 px-4">
                        <img src="/images/oasis.png" alt="About Us" className="rounded-lg shadow-lg" />
                    </div>


                    
        </div>
        </section>
    );
};

export default AboutUsSection;